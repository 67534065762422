<template>
  <div class="container">
    <h1>Ordini</h1>
    <ul>
      <q-order-list
        v-for="(order, o) in orders"
        :key="o"
        :order="order"
      ></q-order-list>
      <!-- <li v-for="(order, o) in orders" :key="o">
        {{ order.created_at | formatDate }}
      </li> -->
    </ul>
  </div>
</template>

<script>
import { API_URL } from "@/app-axios";
import { mapState } from "vuex";
import QOrderList from "../../../_components/QOrderList.vue";
export default {
  components: { QOrderList },
  data: () => ({
    orders: []
  }),
  async mounted() {
    console.log("Carico gli ordini");
    try {
      let order = await API_URL({
        url: `/orders/summary`,
        method: "GET",
        params: {
          customer_id: this.currentUserData.attributes.sub
        },
        headers: {
          "aws-access-token": this.$store.getters["auth/token"]
        }
      });
      this.orders = order.data.content;
      console.log("Cart", order);
    } catch (error) {
      console.log("Errorer cart ", error);
    }
  },
  computed: {
    ...mapState("auth", ["currentUserData"])
  }
};
</script>

<style></style>
