<template>
  <div class="w-full mt-8">
    <div class="border border-gray-500 rounded-md">
      <div
        class="w-full bg-gray-200 text-xs px-4 py-2 grid grid-cols-12 rounded-t-md"
      >
        <div class="col-span-6">
          <h4 class="uppercase">
            ID ORDINE:
          </h4>
          {{ order.order_id }}
        </div>
        <div class="col-span-2">
          <h4 class="uppercase">
            Effettuato il:
          </h4>
          {{ order.due_date | formatDate }}
        </div>
        <div class="col-span-2">
          <h4 class="uppercase">
            Stato:
          </h4>

          <i
            class="mdi mdi-circle"
            :class="
              order.order_status == 'PENDING'
                ? 'text-yellow-500'
                : 'text-green-500'
            "
          ></i>
          {{ order.order_status }}
        </div>
        <div class="col-span-2">
          <h4 class="uppercase">
            totale:
          </h4>

          <strong>{{ order.total | price }}</strong>
        </div>
      </div>
      <div
        class="w-full px-4 py-2 flex"
        v-for="(product, p) in order.products"
        :key="p"
      >
        <div class="w-48 h-20 bg-gray-300">
          <img
            :src="product.product_photo"
            class="w-full h-full object-cover"
            v-if="product.product_photo"
          />
        </div>
        <div class="flex-grow px-4">
          <h1>{{ product.product_name }}</h1>
          <div
            v-html="product.product_description"
            class="text-xs text-gray-500"
          >
            Product description
          </div>
        </div>
        <div>
          <small class="uppercase">totale</small>
          <div class="font-bold ">
            <small>{{ product.quantity }} x </small>
            <span class="text-2xl">{{
              product.single_unit_price | price
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: () => {}
    }
  }
};
</script>

<style></style>
